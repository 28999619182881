import type { TFunction } from 'i18next';
import { DateTime } from 'luxon';
import type { CoreOfficeDataType, OfficePictureData } from '~/types/offices';
import { getNumberOrdinal, hasSpecialSigns, removeSpecialSigns } from './text';

export const cityText = (office: CoreOfficeDataType, lang: string | undefined) => {
  const { city, countryCode, zipCode } = office.addressDetails;

  if (countryCode === 'FR' && city === 'Paris' && zipCode) {
    const zipLastTwoDigit = parseInt(zipCode) % 100;
    if (parseInt(zipCode) > 75000 && zipLastTwoDigit <= 20) {
      const suffix = getNumberOrdinal(zipLastTwoDigit, lang);
      return `${city} ${zipLastTwoDigit}${suffix}`;
    }
  } else return `${city}`;
};

export const floorText = (floor: number | null, lang: string | undefined, t: TFunction) => {
  if (!floor || floor == 0) {
    return t('wizard.addressData.groundFloor.label');
  } else if (floor > 0) {
    return `${floor}${getNumberOrdinal(floor, lang)} ${t('office.floor.label')}`;
  } else {
    return `${floor}${getNumberOrdinal(floor, lang)} ${t('office.floor.basement')}`;
  }
};

//move to utils when cta card branch is merged in
export const getFlexibilityDescription = (flexibility: string, partTimeDays: number[], t: TFunction) => {
  switch (flexibility) {
    case 'fullTime':
      return t('office.show.flexibility.fullTime.text');
    case 'partTime':
      if (partTimeDays.length == 4) {
        return t('office.show.flexibility.partTime.text.oneToFour');
      } else {
        return t('office.show.flexibility.partTime.text.specificDays', {
          listOfDays: joinPartTimeDaysDays(partTimeDays, t),
          dayPluralization:
            partTimeDays.length == 1 && partTimeDays[0] == 1
              ? t('global.pluralization.day.one')
              : t('global.pluralization.day.other'),
        });
      }
    case 'fullAndPartTime':
      if (partTimeDays.length == 4) {
        return t('office.show.flexibility.fullAndPartTime.text.oneToFour');
      } else {
        return t('office.show.flexibility.fullAndPartTime.text.specificDays', {
          listOfDays: joinPartTimeDaysDays(partTimeDays, t),
          dayPluralization:
            partTimeDays.length == 1 && partTimeDays[0] == 1
              ? t('global.pluralization.day.one')
              : t('global.pluralization.day.other'),
        });
      }
  }
};

export const joinPartTimeDaysDays = (days: number[], t: TFunction) => {
  return days.length > 2
    ? [...days.slice(0, -2), days.slice(-2).join(` ${t('global.connectors.or')} `)].join(', ')
    : days.join(t(` ${t('global.connectors.or')} `));
};

export const officeCapacityLabel = (office: CoreOfficeDataType, t: TFunction) => {
  if (office.minCapacity) {
    return `${office.minCapacity}-${office.capacity} ${t('global.pluralization.seat.other')}`;
  }
  return `${office.capacity} ${t(`global.pluralization.seat.${office.capacity === 1 ? 'one' : 'other'}`)}`;
};

export const officeSurfaceLabel = (office: CoreOfficeDataType, t: TFunction) =>
  `${office.surface} ${t('officeSearch.card.surface.squareMeterSymbol')}`;

export const isOfficeAvailableNow = (office: CoreOfficeDataType) =>
  DateTime.fromISO(office.earliestStartDate) <= DateTime.now();

export const officeAvailabilityLabel = (office: CoreOfficeDataType, t: TFunction, fullLabel: boolean = false) => {
  const endDate =
    fullLabel && office.lastAvailabilityDate
      ? DateTime.fromISO(office.lastAvailabilityDate as string).toFormat('dd/MM/yyyy')
      : '';

  if (isOfficeAvailableNow(office)) {
    return fullLabel && endDate
      ? t('office.availability.availableToDate', { endDate: endDate })
      : t('office.availability.availableNow');
  } else {
    const startDate = DateTime.fromISO(office.earliestStartDate).toFormat('dd/MM/yyyy');

    return fullLabel && endDate
      ? t('office.availability.availableFromToDate', { startDate: startDate, endDate: endDate })
      : t('office.availability.availableFromDate') + ' ' + startDate;
  }
};

export const addDashToUuid = (uuid: string | null) => {
  if (!uuid) return '';

  return uuid.toString().slice(0, 3) + '-' + uuid.toString().slice(3, 6);
};

export const getUuidLabel = (uuid: string | null, t: TFunction) => {
  if (!uuid) return '';

  return `${t('office.show.officeReference.label')}: ${uuid}`;
};

export const getCoverUrl = (office: CoreOfficeDataType) => {
  return office.pictures?.find((e: OfficePictureData) => e.imageType === 'cover')?.url || null;
};

export const generateBreadcrumbsData = (
  page: string,
  city: string,
  countryCode: string,
  zipCode: string,
  lang: string,
  t: TFunction,
  title?: string,
): { title: string; href: string }[] => {
  let suffix = '';
  const zipLastTwoDigit = parseInt(zipCode) % 100;
  if (countryCode === 'FR' && city.toLocaleLowerCase() === 'paris' && zipCode) {
    if (parseInt(zipCode) > 75000 && zipLastTwoDigit <= 20) {
      suffix = getNumberOrdinal(zipLastTwoDigit, lang);
    }
  }
  let cityURLSafe = city;
  if (hasSpecialSigns(city)) {
    cityURLSafe = removeSpecialSigns(city);
  }
  const upperCaseCity = city.charAt(0).toUpperCase() + city.slice(1);
  const label = getLabel(page, t);
  const localizedPage = page == 'offices' && lang == 'fr' ? 'bureaux' : page;

  const items = [{ title: `${label} ${upperCaseCity}`, href: `/${lang}/${localizedPage}/${cityURLSafe}` }];

  if (suffix && zipCode) {
    items.push({
      title: `${label} ${upperCaseCity} ${zipLastTwoDigit}${suffix}`,
      href: `/${lang}/${localizedPage}/${cityURLSafe}/${zipCode}`,
    });
  }

  if (title) {
    items.push({ title: validateTitle(title), href: '' });
  }

  return items;
};

const getLabel = (page: string, t: TFunction) => {
  switch (page) {
    case 'coworking':
      return t('breadcrumb.prefix.coworking');
    case 'flexible-offices':
      return t('breadcrumb.prefix.flexibleOffices');
    default:
      return t('breadcrumb.prefix.officesForRent');
  }
};

const locationMap: { [index: string]: string } = {
  'champs-elysees': 'Champs-Élysées',
  republique: 'République',
  opera: 'Opéra',
};

const validateTitle = (title: string) => {
  return locationMap[title] || title;
};
