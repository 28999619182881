import { arrayFromRange } from '~/components/utils/misc';
import { DateTime } from 'luxon';
import type { CoreOfficeDataType } from '~/types/offices';
import { TFunction } from 'i18next';

export const initializeCapacityOptions = (minCapacity: number | null, capacity: number, _t: TFunction) => {
  if (!minCapacity) {
    return [{ value: capacity, label: capacity }];
  }
  return [...arrayFromRange(minCapacity, capacity).map((n) => ({ value: n, label: n }))];
};

export const initializeFlexibilityOptions = (flexibility: string, partTimeDays: Array<number>, t: TFunction) => {
  let flexibilityOptions: {
    value: string;
    label: string;
  }[] = [];

  if (['full_and_part_time', 'part_time'].includes(flexibility)) {
    flexibilityOptions = flexibilityOptions.concat([
      ...partTimeDays.map((n) => ({
        value: n.toString(),
        label: t(`office.show.requestVisitCard.flexibility.daysPerWeek`, {
          days: n.toString(),
          dayPluralization: n == 1 ? t('global.pluralization.day.one') : t('global.pluralization.day.other'),
        }),
      })),
    ]);
  }

  if (['full_and_part_time', 'full_time'].includes(flexibility)) {
    flexibilityOptions = flexibilityOptions.concat([
      { value: '5', label: t('office.show.requestVisitCard.flexibility.fullTime').toString() },
    ]);
  }
  return flexibilityOptions;
};

export const getComputedPrice = (
  selectedCapacity: number | null,
  numOfDays: number | null,
  officeData: CoreOfficeDataType,
) => {
  if (!selectedCapacity || !numOfDays) {
    return officeData.pricePerMonthValue;
  }

  const flexibilityToPriceMap: { [key: number]: number } = {
    1: officeData.pricePerMonthOneDay,
    2: officeData.pricePerMonthTwoDay,
    3: officeData.pricePerMonthThreeDay,
    4: officeData.pricePerMonthFourDay,
    5: officeData.pricePerMonthValue,
  };

  if (officeData.priceFlexibility)
    return Math.ceil((flexibilityToPriceMap[numOfDays] / officeData.capacity) * selectedCapacity);
  else return flexibilityToPriceMap[numOfDays];
};

export const getPriceAfterDiscounts = (
  price: any,
  longTermDiscountPercentage: any,
  LastMinuteDiscountPercentage: any,
) => {
  let priceAfterDiscount = price;
  if (longTermDiscountPercentage) {
    priceAfterDiscount = priceAfterDiscount - price * (longTermDiscountPercentage / 100);
  }
  if (LastMinuteDiscountPercentage) {
    priceAfterDiscount = priceAfterDiscount - price * (LastMinuteDiscountPercentage / 100);
  }
  return priceAfterDiscount;
};

export const longTermDiscountApplied = (longTermDiscount: any, startDate: any, endDate: any) => {
  if (!startDate || !endDate || !longTermDiscount) return false;
  const diff = endDate.diff(startDate, 'months');
  return diff.toObject().months > longTermDiscount;
};

export const lastMinuteDiscountApplied = (lastMinuteDiscount: any, startDate: any) => {
  if (!startDate || !lastMinuteDiscount) return false;
  const diff = startDate.diff(DateTime.now(), 'days');
  return diff.days < lastMinuteDiscount;
};

export const percentageOf = (percentage: any, price: any) => {
  return price * (percentage / 100);
};

export const getPricePerSeat = (
  numOfDays: number | null,
  office: CoreOfficeDataType,
  requiredCapacity?: number | null,
) => {
  if (!numOfDays) {
    return office.cheapestPricePerSeatPerMonth;
  }

  const flexibilityToPriceMap: { [key: number]: number } = {
    1: office.pricePerMonthOneDay,
    2: office.pricePerMonthTwoDay,
    3: office.pricePerMonthThreeDay,
    4: office.pricePerMonthFourDay,
    5: office.pricePerMonthValue,
  };
  if (!office.priceFlexibility && requiredCapacity) return flexibilityToPriceMap[numOfDays] / requiredCapacity;
  else return flexibilityToPriceMap[numOfDays] / office.capacity;
};

export const getFixedFlexibilityCopies = (flexibility: string, partTimeDays: Array<number>, t: TFunction) => {
  if (flexibility === 'full_time') {
    return {
      label: t('office.show.requestVisitCard.fixedFlexibility.fullTime.label'),
      displayText: t('office.show.requestVisitCard.fixedFlexibility.fullTime.displayText'),
    };
  } else if (flexibility === 'part_time' && partTimeDays.length === 1) {
    return {
      label: t('office.show.requestVisitCard.fixedFlexibility.partTime.label'),
      displayText: t('office.show.requestVisitCard.fixedFlexibility.partTime.displayText', {
        days: partTimeDays[0],
        dayPluralization: t('global.pluralization.i18next.day', { count: partTimeDays[0] }),
      }),
    };
  } else {
    return {
      label: '',
      displayText: '',
    };
  }
};

export const getFixedCapacityAndFlexibilityCopies = (
  capacity: number,
  flexibility: string,
  partTimeDays: Array<number>,
  t: TFunction,
) => {
  const label = `${capacity} ${t('global.pluralization.i18next.seat', { count: capacity })}`;

  let displayText = '';

  if (flexibility === 'full_time') {
    displayText = t('office.show.requestVisitCard.fixedSeatsAndFlexibility.fullTime.displayText');
  } else if (flexibility === 'part_time' && partTimeDays.length === 1) {
    displayText = t('office.show.requestVisitCard.fixedSeatsAndFlexibility.partTime.displayText', {
      days: partTimeDays[0],
      dayPluralization: t('global.pluralization.i18next.day', { count: partTimeDays[0] }),
    });
  }
  return {
    displayText: displayText,
    label,
  };
};

export const isCapacityFixed = (minCapacity: number | null, capacity: number) => {
  return !minCapacity || capacity === minCapacity;
};

export const isFlexibilityFixed = (flexibility: string, partTimeDays: Array<number>) => {
  return flexibility === 'full_time' || (flexibility === 'part_time' && partTimeDays.length === 1);
};
